<template>
  <slot />
</template>

<script setup lang="ts">
const addressStore = useAddressStore()

onMounted(async () => {
  await addressStore.checkAllTerminals()
})
</script>
